import { Geoposition } from './types';

const MoscowGeoposition = { longitude: '37.627931', latitude: '55.756754' };

export const getMapLink = ({ longitude, latitude }: Geoposition) =>
    `https://yandex.ru/maps/?ll=${longitude},${latitude}&z=12&text=МТС%20Банк,%20отделения`;
export const defaultMapLink = getMapLink(MoscowGeoposition);

export const mapLink = ({ longitude, latitude }: Geoposition) =>
    window.open(`https://yandex.ru/maps/?ll=${longitude},${latitude}&z=12&text=МТС%20Банк,%20отделения`, '_blank', 'noopener, noreferrer');
