import { REDIRECT_URL, SESSION_EXPIRED } from 'constants/index';
import { getIsSessionExpired, dispatchSessionExpired } from './getIsSessionExpired';
import { isWebview } from './isWebview';

// "proxy": "http://sso-test.mbrd.ru:8090", - add to package.json for local dev
// "proxy": "http://sso-dev.mbrd.ru:8090", - backend dev stand for testing locally

export interface ResponseError extends Error {
    // Дополнительные поля, которые будут динамически добавляться
    [key: string]: unknown;
    body?: string;
    comments?: string;
    description?: string;
    id?: string;
    messageCode?: string;
    responseCode?: string;
    status?: number;
    system?: string;
}

const isEmpty = (data: unknown) => JSON.stringify(data) === '{}';
const toCamelCase = (str: string): string => str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

const request = async (method: string, url: string, data = {}, headers?: Record<string, unknown>) => {
    if (getIsSessionExpired()) {
        dispatchSessionExpired();

        if (!isWebview()) {
            return window.location.assign(REDIRECT_URL);
        }

        throw new Error(SESSION_EXPIRED);
    }

    const serverUrl = `/api${url}`;
    const commonHeaders = {
        'Content-Type': 'application/json',
        Accept: '*/*',
    };

    const options: RequestInit = {
        method,
        headers: { ...commonHeaders, ...headers },
        credentials: 'include',
    };

    if (method !== 'GET' && !isEmpty(data)) {
        options.body = JSON.stringify(data);
    }

    const response = await fetch(serverUrl, options);

    if (!response.ok) {
        const errorData: { [key: string]: string } = await response.json();

        const error = new Error(typeof errorData.error === 'string' ? errorData.error : 'Unknown error') as ResponseError;

        error.status = response.status;

        if (typeof errorData === 'object') {
            error.messageCode = errorData?.error;
            error.description = errorData?.error_description;
            error.id = errorData?.error_id;

            // Набор ключей, которые были обработаны явно
            const explicitKeys = new Set([
                'error', // обработано как messageCode
                'error_description', // обработано как description
                'error_id', // обработано как id
            ]);

            Object.entries(errorData).forEach(([key, value]) => {
                if (explicitKeys.has(key)) {
                    return;
                }

                // Если имя свойства содержит '_', преобразуем в camelCase
                const prop = key.includes('_') ? toCamelCase(key) : key;
                error[prop] = value;
            });
        } else {
            error.body = String(errorData);
        }

        if (response.status === 400 && errorData.error === 'invalid_request' && !isWebview()) {
            return window.location.assign(REDIRECT_URL);
        }

        throw error;
    }

    return response;
};

/**
 * todo добавлен для методов webauthn, требуется отрефакторить методо request и использовать его для всех api
 */
const fetcher = <Rs, Rq = undefined, H = undefined>(method: string, url: string, data?: Rq, headers?: H): Promise<Rs> =>
    fetch(url, {
        method,
        credentials: 'include',
        headers: {
            ...(headers || {}),
            'Content-Type': 'application/json',
        },
        ...(data && { body: JSON.stringify(data) }),
    }).then((response) =>
        response.json().then((jsonResponse: Rs) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(jsonResponse));
            }

            return jsonResponse;
        })
    );

const get = (url: string, data = {}, headers?: Record<string, unknown>) => request('GET', url, data, headers);
const post = (url: string, data = {}, headers?: Record<string, unknown>) => request('POST', url, data, headers);
const postJSON = (...args: Parameters<typeof post>) => post(...args).then((response) => response && response.json());

/**
 * у ios 15.(5+) есть баг при повторном вызове authenticator (https://forums.developer.apple.com/forums/thread/710087)
 * проблема свзяна с вызовом fetch, в ios 16 она исправлена
 * для обхода проблемы для ios 15.5 fetch заменён на XMLHttpRequest
 */
const getChallenge = () => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.open('GET', '/webauthn/challenge', true);

    xhr.send();

    return new Promise<string>((resolve, reject) => {
        xhr.onload = () => {
            const { challenge } = xhr.response as { challenge: string };
            resolve(challenge);
        };

        xhr.onerror = () => {
            reject(new Error());
        };
    });
};
const verifyCredentials = (credentialData: Credential) =>
    fetcher<{ result: boolean }, Credential>('post', '/webauthn/publickey', credentialData).then((response) => response.result);
const login = (assertion: Credential) =>
    fetcher<{ [key: string]: string }, Credential>('post', '/api/v2/login', assertion).then((response) => response);

export const api = {
    customCookies: {},
    customHeaders: {},
    get,
    post,
    postJSON,
    getChallenge,
    verifyCredentials,
    login,
};
