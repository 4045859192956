import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { Content } from 'components/common/Content';
import { LinkButtonOutline } from 'components/common/LinkButtonOutline';
import { Text } from 'components/common/Text';
import { modalsData } from 'lib/modalsData';
import { ModalProps } from 'components/common/Modal';
import { StepType } from 'components/steps/Steps';
import { doSuccess } from 'utils/doSuccess';
import { apiUrls } from 'lib/apiUrls';
import { api, ResponseError } from 'utils/api';
import { StateContext } from 'state';
import { restrictedParams, RestrictedTypes } from 'lib/restrictedParams';
import { isWebview } from 'utils/isWebview';
import { sendEventToApp } from 'utils/sendEventToApp';
import { useDidMount } from '@mtsbank/ui-kit';
import {
    clickRestrictedGTM,
    showScreenRestrictedGTM,
    EventLabelsGTM,
    ScreenNamesGTM,
    EventContext,
    AuthTypeGTM,
    logFailedAuthGTM,
} from 'utils/GTM';
import { getLinkToMap } from 'utils/setLinkToMap';

const IS_SHOWED_STEP_RESTRICTED = 'isShowedStepRestricted';

export const Restricted = () => {
    const {
        setDisplayStep,
        setModalProps,
        restrictedType,
        setLoading,
        getErrorModal,
        closeModal,
        toggleModal,
        updateClient,
        login,
    } = useContext(StateContext);
    const [linkToBankBranches, setLinkToBankBranches] = useState('');

    useDidMount(() => {
        const isShowedStepRestricted = window.sessionStorage.getItem(IS_SHOWED_STEP_RESTRICTED);
        if (!isShowedStepRestricted) {
            window.sessionStorage.setItem(IS_SHOWED_STEP_RESTRICTED, 'true');
        } else {
            window.sessionStorage.setItem(IS_SHOWED_STEP_RESTRICTED, '');
            setDisplayStep('login');
        }
        getLinkToMap().then((link: string) => {
            setLinkToBankBranches(link);
        });
    });

    const modalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.loginLock,
            onPrimaryClick: 'callToBank',
            onSecondaryClick: (e) => {
                sendEventToApp();
                updateClient()(e);
            },
        }),
        [updateClient]
    );

    const getModal = useCallback(
        (props: ModalProps, step: StepType = 'login') => {
            setDisplayStep(step);
            setModalProps(props);
            toggleModal();
        },
        [setDisplayStep, setModalProps, toggleModal]
    );

    const onSecondaryClick = useCallback(
        async (type: string) => {
            setLoading(true);
            const url = type === 'wrongNumber' ? apiUrls.register : apiUrls.cancel;
            const data = type === 'wrongNumber' ? { by_phone: true } : {};
            const eventContextGTM = type === 'wrongNumber' ? EventContext.ANOTHER_LINKED_NUMBER : EventContext.SOMEONE_ELSE_PHONE;

            try {
                const result = await api.postJSON(url, data);
                setLoading(false);
                if (result.redirect_url) {
                    doSuccess({
                        login,
                        redirect_url: result.redirect_url,
                        gtmProps: { authTypeGTM: AuthTypeGTM.RESTRICTED, eventContextGTM },
                    });

                    return;
                }
            } catch (unknownError) {
                const error = unknownError as ResponseError;
                setLoading(false);
                logFailedAuthGTM(AuthTypeGTM.RESTRICTED, { login, eventContextGTM, error });
                if (error.status === 403) {
                    getModal(modalProps);
                    return;
                }
                getErrorModal(
                    () => {
                        closeModal();
                        setLoading(true);
                        setTimeout(() => onSecondaryClick(type), 300);
                    },
                    error,
                    ScreenNamesGTM.WARNING
                );
            }
        },
        [login, closeModal, getErrorModal, getModal, modalProps, setLoading]
    );

    const params = restrictedParams[restrictedType];

    const isWrongNumber = useMemo(() => restrictedType === RestrictedTypes.wrongNumber, [restrictedType]);
    const isWrongAccount = useMemo(() => restrictedType === RestrictedTypes.wrongAccount || restrictedType === RestrictedTypes.wrongData, [
        restrictedType,
    ]);

    useEffect(() => {
        if (isWrongNumber) {
            showScreenRestrictedGTM({ isWrongNumber: true });
        }
        if (isWrongAccount) {
            showScreenRestrictedGTM({ isWrongNumber: false });
        }
    }, [isWrongAccount, isWrongNumber]);

    return (
        <>
            <Content heading={params.heading}>
                <Text>{params.firstText}</Text>
                {'lastText' in params && <AdditionalText>{params.lastText}</AdditionalText>}
                {isWebview() ? (
                    <RestrictedButton
                        as="a"
                        onClick={() =>
                            clickRestrictedGTM({ login, isWrongNumber, isWrongAccount, eventLabel: EventLabelsGTM.CALL_TO_BANK })
                        }
                        href="tel:+78002500520"
                        styleType="primary"
                    >
                        {params.primaryButton}
                    </RestrictedButton>
                ) : (
                    <RestrictedButton
                        as="a"
                        onClick={() =>
                            clickRestrictedGTM({ login, isWrongNumber, isWrongAccount, eventLabel: EventLabelsGTM.CALL_TO_BANK })
                        }
                        href="https://www.mtsbank.ru/o-banke/kontakti/"
                        target="_blank"
                        rel="nofollow norefferrer noopener"
                        styleType="primary"
                    >
                        {params.primaryButton}
                    </RestrictedButton>
                )}

                <LinkButton
                    onClick={() => clickRestrictedGTM({ login, isWrongNumber, isWrongAccount, eventLabel: EventLabelsGTM.OFFICES_MAP })}
                    href={linkToBankBranches}
                    target="_blank"
                    styleType="outline"
                    rel="noopener noreferrer nofollow"
                    aria-label={`${params.linkButton} Яндекса`}
                >
                    {params.linkButton}
                </LinkButton>
                {'secondaryButton' in params && (
                    <RestrictedButton
                        type="button"
                        onClick={() => {
                            clickRestrictedGTM({ login, isWrongNumber, isWrongAccount, eventLabel: EventLabelsGTM.LIMITED_MODE });
                            onSecondaryClick(restrictedType);
                        }}
                        styleType="hollow"
                    >
                        {params.secondaryButton}
                    </RestrictedButton>
                )}
            </Content>
        </>
    );
};

const AdditionalText = styled(Text)`
    margin-top: 1.5rem;
`;

const RestrictedButton = styled(Button)`
    text-decoration: none;
    margin-top: 0.75rem;
`;

const LinkButton = styled(LinkButtonOutline)`
    margin: 0.75rem 0 0;
`;
