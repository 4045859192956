import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Content } from 'components/common/Content';
import { LinkButton } from 'components/common/LinkButton';
import { ResendText } from 'components/common/ResendText';
import { Error } from 'components/common/Error';
import { Spacer } from 'components/common/Spacer';
import { OtpField } from 'components/fields/OtpDigitsField';
import { StateContext } from 'state';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { doSuccess } from 'utils/doSuccess';
import { modalsData } from 'lib/modalsData';
import { StepType } from 'components/steps/Steps';
import { getLeftAttempts } from 'utils/getLeftAttemptsMessage';
import { ModalProps } from 'components/common/Modal';
import { api, ResponseError } from 'utils/api';
import { apiUrls } from 'lib/apiUrls';
import { useHistory } from 'react-router-dom';
import { isOtpError, otpErrors } from 'lib/otpErrors';
import { getNumericValue } from 'utils/getNumericValue';
import { stepParams } from 'lib/stepParams';
import { isWebview } from 'utils/isWebview';
import { getAdditionalQueryParams } from 'utils/getAdditionalQueryParams';
import { sendEventToApp } from 'utils/sendEventToApp';
import { WebauthnCheck } from 'utils/webauthn/WebauthnCheck';
import { REDIRECT_URL, resendCooldownSeconds, SESSION_EXPIRED, WEBAUTHN_PATH } from 'constants/index';
import { LocationState } from 'types/webauthn';
import { Text } from 'components/common/Text';
import {
    clickBackGTM,
    resendOtpGTM,
    showScreenOtpGTM,
    submitOtpGTM,
    FilterName,
    ScreenNamesGTM,
    AuthTypeGTM,
    successConfirmationOtpGTM,
    logFailedAuthGTM,
} from 'utils/GTM';
import { SessionStorageKey } from 'types';
import { isNumber } from 'util';
import { handleLinkToMap } from 'utils/setLinkToMap';

const DEAFAULT_ATTEMPTS = 3;

type FormValues = {
    otp: string;
};

const { fromAccountList } = getAdditionalQueryParams();

export const Otp = () => {
    const {
        otp: { heading, subheading },
    } = stepParams;

    const history = useHistory<LocationState>();

    const {
        login,
        setLogin,
        setDisplayStep,
        setLoading,
        setModalProps,
        setIdentityAttempts,
        setUsername,
        getErrorModal,
        closeModal,
        toggleModal,
        updateClient,
        openPolicy,
    } = useContext(StateContext);

    const getInitialAttemptsLeft = (key: SessionStorageKey.OTP_RESEND_ATTEMPTS_LEFT | SessionStorageKey.OTP_CONFIRM_ATTEMPTS_LEFT) => {
        const storedValue = sessionStorage.getItem(key);

        if (storedValue == null || storedValue === undefined) {
            return DEAFAULT_ATTEMPTS;
        }

        const parsed = Number(storedValue);

        return Number.isNaN(parsed) ? DEAFAULT_ATTEMPTS : parsed;
    };

    const [resendAttemptsLeft, setResendAttemptsLeft] = useState(getInitialAttemptsLeft(SessionStorageKey.OTP_RESEND_ATTEMPTS_LEFT));
    const [resendTimer, setResendTimer] = useState(
        Number(sessionStorage.getItem(SessionStorageKey.OTP_RESEND_TIMER)) ?? resendCooldownSeconds
    );
    const [otpConfirmAttempsLeft, setOtpConfirmAttempsLeft] = useState(getInitialAttemptsLeft(SessionStorageKey.OTP_CONFIRM_ATTEMPTS_LEFT));

    // Остальные стейты
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState<string>('');
    const [isError, setError] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const { register, setValue } = useForm<FormValues>();

    // хелпер, чтобы удобно обновлять attempts в state + sessionStorage
    const updateResendAttemptsLeft = useCallback((newVal: number) => {
        setResendAttemptsLeft(newVal);
        sessionStorage.setItem(SessionStorageKey.OTP_RESEND_ATTEMPTS_LEFT, String(newVal));
    }, []);

    // хелпер, чтобы удобно обновлять timer в state + sessionStorage
    const updateResendTimer = useCallback((newVal: number) => {
        setResendTimer(newVal);
        sessionStorage.setItem(SessionStorageKey.OTP_RESEND_TIMER, String(newVal));
    }, []);

    const updateConfirmationAttemptsLeft = useCallback((newVal: number) => {
        setOtpConfirmAttempsLeft(newVal);
        sessionStorage.setItem(SessionStorageKey.OTP_CONFIRM_ATTEMPTS_LEFT, String(newVal));
    }, []);

    const limitModalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.smsLimit,
            onPrimaryClick: (e) => {
                sendEventToApp();
                updateClient()(e);
            },
        }),
        [updateClient]
    );

    const afModalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.smsLock,
            type: 'af',
            onPrimaryClick: 'callToBank',
            onSecondaryClick: handleLinkToMap,
        }),
        []
    );

    const lockedModalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.loginLock,
            onPrimaryClick: 'callToBank',
            onSecondaryClick: (e) => {
                sendEventToApp();
                updateClient()(e);
            },
        }),
        [updateClient]
    );
    // костыль для аналитики
    const lockedModalProps2 = useMemo<ModalProps>(
        () => ({
            ...modalsData.loginLock2,
            onPrimaryClick: 'callToBank',
            onSecondaryClick: (e) => {
                sendEventToApp();
                updateClient()(e);
            },
        }),
        [updateClient]
    );

    const reloadAppModal = useMemo<ModalProps>(
        () => ({
            ...modalsData.sessionExpired,
            onPrimaryClick: () => {
                // todo завести гтм метрику
                closeModal();
            },
        }),
        [closeModal]
    );

    const notFoundModalProps = useMemo(
        () => ({
            ...modalsData.smsNotFound,
            onPrimaryClick: updateClient('restricted', 'wrongNumber'),
            onSecondaryClick: updateClient('nickname'),
        }),
        [updateClient]
    );

    const displayNextStep = useCallback(
        (step: StepType) => {
            setSubmitting(false);
            setLogin('');
            setDisplayStep(step);
        },
        [setLogin, setSubmitting, setDisplayStep]
    );

    const getInputError = useCallback((text: string, count: number) => [text, getLeftAttempts(count)].join('\n'), []);

    const clearInput = useCallback(() => {
        setValue('otp', '');
        setInputValue('');
    }, [setValue]);

    const getModal = useCallback(
        (props: ModalProps, step: StepType = 'login') => {
            displayNextStep(step);
            setModalProps(props);
            toggleModal();
        },
        [displayNextStep, setModalProps, toggleModal]
    );

    /**
     * Запускаем таймер resendTimer только если:
     * - Остались попытки resendAttemptsLeft > 0
     * - И сам resendTimer > 0
     */
    useEffect(() => {
        let timerId: number = 0;
        if (resendAttemptsLeft > 0 && resendTimer > 0) {
            timerId = window.setTimeout(() => {
                updateResendTimer(resendTimer - 1);
            }, 1000);
        }
        return () => clearTimeout(timerId);
    }, [resendTimer, resendAttemptsLeft, updateResendTimer]);

    // check attempts and error status from session storage
    useEffect(() => {
        sessionStorage.removeItem(SessionStorageKey.OTP_RESEND_ATTEMPTS_LEFT);
        sessionStorage.removeItem(SessionStorageKey.OTP_RESEND_TIMER);

        if (sessionStorage.getItem(SessionStorageKey.ERROR)) {
            setError(true);
            setInputError(sessionStorage.getItem(SessionStorageKey.ERROR) || '');
        }
        if (sessionStorage.getItem(SessionStorageKey.OTP_RESEND_TIMER)) {
            const currentTimer = Number(sessionStorage.getItem(SessionStorageKey.OTP_RESEND_TIMER)) < 1 ? -1 : resendCooldownSeconds;
            setResendTimer(currentTimer);
        }

        return () => {
            sessionStorage.removeItem(SessionStorageKey.ERROR);
            sessionStorage.removeItem(SessionStorageKey.OTP_RESEND_TIMER);
            sessionStorage.removeItem(SessionStorageKey.OTP_RESEND_ATTEMPTS_LEFT);
        };
    }, []);

    // update error status
    useEffect(() => {
        if (sessionStorage.error) {
            setError(true);
        }
        sessionStorage.setItem(SessionStorageKey.OTP_RESEND_ATTEMPTS_LEFT, String(resendAttemptsLeft)); // todo возможно надо еще обновлять OTP_CONFIRM_ATTEMPTS_LEFT
    }, [setError, inputError, resendAttemptsLeft]);

    // Если у нас кончились попытки resendAttemptsLeft — ставим таймер в -1 (чтобы не дергался)
    useEffect(() => {
        if (resendAttemptsLeft < 1) {
            updateResendTimer(-1);
        }
    }, [resendAttemptsLeft, updateResendTimer]);

    // enable loader during request to server
    useEffect(() => {
        setLoading(isSubmitting);
        return () => setLoading(false);
    }, [isSubmitting, setLoading]);

    const handleOtpError = useCallback(
        ({ type, message = '' }: { message?: string; type?: string }) => {
            const otpConfirmationAttemptsLeft = type === 'limit' ? 0 : otpConfirmAttempsLeft - 1;

            if (type === 'blocked') {
                getModal(lockedModalProps);
                return;
            }
            if (type === 'af') {
                getModal(afModalProps);
                return;
            }
            if (type === 'login') {
                displayNextStep('login');
                return;
            }
            if (type === 'limit' || otpConfirmationAttemptsLeft < 1) {
                // todo поправил (удалить) ? зачем вообще показывать модалку при ресенде?
                getModal(limitModalProps);
            } else {
                const errorMessage = getInputError(message, otpConfirmationAttemptsLeft);
                sessionStorage.setItem(SessionStorageKey.ERROR, errorMessage);
                setInputError(errorMessage);
                updateConfirmationAttemptsLeft(otpConfirmationAttemptsLeft);
                clearInput();
            }
        },
        [
            getModal,
            lockedModalProps,
            afModalProps,
            displayNextStep,
            limitModalProps,
            getInputError,
            otpConfirmAttempsLeft,
            updateConfirmationAttemptsLeft,
            clearInput,
        ]
    );

    const handleNextStep = useCallback(
        (result: { count: number; name: string; next_step: string }) => {
            if (result.next_step === 'sign_policy' && !isWebview()) {
                setSubmitting(false);
                openPolicy();
            }
            if (result.next_step === 'check_additional_data') {
                setSubmitting(false);
                setDisplayStep('confirmation');

                setIdentityAttempts(result.count);
                setUsername(result.name);
            }
            if (result.next_step === 'register') {
                if (isWebview()) {
                    logFailedAuthGTM(AuthTypeGTM.OTP, { login, message: FilterName.NEXT_STEP_REGISTER });
                    getModal(notFoundModalProps);
                } else {
                    setDisplayStep('nickname');
                }
            }
        },
        [getModal, login, notFoundModalProps, openPolicy, setDisplayStep, setIdentityAttempts, setUsername]
    );

    const onSubmit = useCallback(
        async (data: FormValues) => {
            setSubmitting(true);
            submitOtpGTM({ login });

            const requestData = { code: data.otp, tid: sessionStorage.getItem(SessionStorageKey.TID) };

            try {
                const result = await api.postJSON(apiUrls.confirm, requestData);
                updateResendAttemptsLeft(otpConfirmAttempsLeft - 1);

                if (result.redirect_url) {
                    successConfirmationOtpGTM({ login });

                    if (isWebview()) {
                        doSuccess({ login, redirect_url: result.redirect_url, gtmProps: { authTypeGTM: AuthTypeGTM.OTP } });

                        return;
                    }

                    const webauthnCheck = new WebauthnCheck();

                    webauthnCheck.checkWebauthnAvailability().then((available) => {
                        if (available && webauthnCheck.isNeedWebauthnEnable() && !history.location.state?.webauthn) {
                            history.push(WEBAUTHN_PATH, { redirectUrl: result.redirect_url });
                        } else {
                            doSuccess({
                                login,
                                redirect_url: result.redirect_url,
                                gtmProps: { authTypeGTM: AuthTypeGTM.OTP },
                            });
                        }
                    });

                    return;
                }

                if (result.next_step) {
                    successConfirmationOtpGTM({ login });
                    handleNextStep(result);
                }
            } catch (unknownError) {
                const error = unknownError as ResponseError;
                const { message } = error;
                const currentAttempt = resendAttemptsLeft - 1;
                logFailedAuthGTM(AuthTypeGTM.OTP, { login, error, eventValue: currentAttempt });
                setSubmitting(false);
                if (isNumber(error.status) && error.status >= 400 && error?.status < 404 && isOtpError(message)) {
                    const otpError = otpErrors[message];
                    handleOtpError(otpError);
                } else {
                    getErrorModal(
                        () => {
                            setSubmitting(true);
                            closeModal();
                            setTimeout(() => onSubmit(data), 300);
                        },
                        error,
                        ScreenNamesGTM.LOGIN
                    );
                }
            }
        },
        [
            login,
            updateResendAttemptsLeft,
            otpConfirmAttempsLeft,
            history,
            handleNextStep,
            resendAttemptsLeft,
            handleOtpError,
            getErrorModal,
            closeModal,
        ]
    );

    const onChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            if (isSubmitting) {
                return;
            }

            const otp = target.value;
            const value = getNumericValue(otp);
            setValue('otp', value);
            setInputValue(value); // sets value to displayable digits cells from hidden input
            if (value.length === 4) {
                onSubmit({ otp: value }); // todo ? чекнуть
            }
        },
        [isSubmitting, onSubmit, setValue]
    );

    const onClickGoBack = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            clickBackGTM({ login });
            displayNextStep('login');
        },
        [displayNextStep, login]
    );

    const onClickResend = useCallback(async () => {
        setSubmitting(true);
        resendOtpGTM({ login });
        try {
            const result = await api.postJSON(apiUrls.resend);
            setSubmitting(false);
            updateResendAttemptsLeft(Number(result.count));
            updateResendTimer(resendCooldownSeconds);
        } catch (unknownError) {
            const error = unknownError as ResponseError;
            setSubmitting(false);

            const isSessionExipred = error.message.includes(SESSION_EXPIRED);
            if (isSessionExipred && !isWebview) {
                window.location.assign(REDIRECT_URL);
                return;
            }

            setSubmitting(false);
            if (error.message === 'limit_sending_sms_code') {
                updateResendAttemptsLeft(-1);
                getModal(limitModalProps);
                return;
            }

            const modalData = isSessionExipred ? reloadAppModal : lockedModalProps2;
            logFailedAuthGTM(AuthTypeGTM.OTP, { login, error, message: FilterName.EXHAUSTED_ATTEMPTS });

            getModal(modalData);
        }
    }, [login, getModal, limitModalProps, lockedModalProps2, reloadAppModal, updateResendAttemptsLeft, updateResendTimer]);

    useEffect(() => {
        showScreenOtpGTM({ login });
    }, [login]);

    return (
        <Content heading={heading} subheading={subheading(login)}>
            <div>
                <OtpField register={register} inputValue={inputValue} isError={isError} onChange={onChange} />
                <Spacer height="1rem" />
                {isError && <Error data-testid="form-error">{inputError}</Error>}
            </div>
            {resendTimer > 0 && <ResendText resendTimer={resendTimer} />}
            {resendTimer === 0 && resendAttemptsLeft > 0 && (
                <LinkButton resend topSpace="1.25rem" onClick={onClickResend}>
                    Отправить код повторно
                </LinkButton>
            )}
            {resendAttemptsLeft <= 0 && <Text>Слишком много попыток</Text>}

            {!fromAccountList && <LinkButton onClick={onClickGoBack}>Назад</LinkButton>}
            <Spacer height={isError ? '2.75rem' : '3.5rem'} />
        </Content>
    );
};
