import { FormatAliasPhone, formatPhoneNumber } from 'utils/formatPhoneNumber';
import { doAuthGTM } from 'utils/GTM/authEvents';
import { DoAuthPropsGTM } from 'utils/GTM/types';
import { redirect } from 'utils/redirect';

interface DoSuccessParams {
    gtmProps: DoAuthPropsGTM;
    login: string;
    redirect_url: string;
}

type DoSuccess = (params: DoSuccessParams) => void;

export const doSuccess: DoSuccess = ({ login, redirect_url, gtmProps }) => {
    if (redirect_url === '/api/authorize') {
        doAuthGTM(login, gtmProps);
        window.gib.setAuthStatus(window.gib.IS_AUTHORIZED);
        window.gib.setIdentityAndEncrypt(formatPhoneNumber(login, FormatAliasPhone.DIGIT11));
    }

    redirect(redirect_url);
    sessionStorage.clear();
};
