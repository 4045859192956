import { Geoposition } from 'utils/types';

export const getGeoposition = (): Promise<Geoposition> => {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            reject();
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                resolve({ latitude, longitude });
            },
            () => {
                reject();
            },
            { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
        );
    });
};
