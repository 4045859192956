import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { CardType, Card } from 'components/common/Card';
import { Content } from 'components/common/Content';
import { LinkButton } from 'components/common/LinkButton';
import { StateContext } from 'state';
import { modalsData } from 'lib/modalsData';
import { Identity } from 'utils/formatIdentityInput';
import { stepParams } from 'lib/stepParams';
import { ModalProps } from 'components/common/Modal';
import { iAmNotCLickGTM, identificationCLickGTM, showScreenConfirmationGTM } from 'utils/GTM';
import { handleLinkToMap } from 'utils/setLinkToMap';

const DEFAULT_ATTEMPTS_ITEM = { login: '', time: '' };

export const Confirmation = () => {
    const {
        confirmation: { heading, subheading },
    } = stepParams;
    const {
        username,
        setDisplayStep,
        setIdentityType,
        setModalProps,
        toggleModal,
        login,
        setIdentityAttempts,
        getIsBlockedTime,
        blockAttempts,
        setBlockAttempts,
    } = useContext(StateContext);
    const firstName = username.length > 0 && username.split(' ')[0];
    const user = username.length > 0 ? `Здравствуйте, ${firstName}!\n` : '';
    const fullHeading = [user, heading].join('');

    const modalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.wrongPerson,
            onPrimaryClick: 'callToBank',
            onSecondaryClick: handleLinkToMap,
        }),
        []
    );
    const isUserName = Boolean(username);

    const handleIdentityAttempts = useCallback(() => {
        if (blockAttempts.login) {
            const isCurrentLogin = blockAttempts.login === login;
            if (!isCurrentLogin) {
                setIdentityAttempts(3);
                /**
                 * Временное решение поправить баг вечной блокировки сессии в Identity SITE-3987,
                 * надо завязать логику сессии к конкретному аккаунту
                 */

                return;
            }

            const isBlockedTime = getIsBlockedTime(blockAttempts.time, isCurrentLogin);

            if (isCurrentLogin && !isBlockedTime) {
                setIdentityAttempts(3);
                setBlockAttempts(DEFAULT_ATTEMPTS_ITEM);
                sessionStorage.setItem('blockAttempts', JSON.stringify(DEFAULT_ATTEMPTS_ITEM));
            }
        }
    }, [blockAttempts.login, blockAttempts.time, getIsBlockedTime, login, setBlockAttempts, setIdentityAttempts]);

    const onClickId = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            identificationCLickGTM({ isPassport: true, login });
            setIdentityType(Identity.id);
            setDisplayStep('identityID');
            handleIdentityAttempts();
        },
        [login, setIdentityType, setDisplayStep, handleIdentityAttempts]
    );

    const onClickCard = useCallback(
        (e: React.MouseEvent) => {
            identificationCLickGTM({ login });
            e.preventDefault();
            setIdentityType(Identity.card);
            setDisplayStep('identityCard');
            handleIdentityAttempts();
        },
        [login, setIdentityType, setDisplayStep, handleIdentityAttempts]
    );

    const onButtonClick = useCallback(() => {
        iAmNotCLickGTM({ login });

        if (isUserName) {
            setModalProps(modalProps);
            toggleModal();
        }
    }, [login, isUserName, setModalProps, modalProps, toggleModal]);

    useEffect(() => {
        showScreenConfirmationGTM();
    }, []);

    return (
        <Content heading={fullHeading} subheading={subheading}>
            <Card type={CardType.menuId} first onClick={onClickId} />
            <Card type={CardType.menuCard} onClick={onClickCard} />
            <LinkButton onClick={onButtonClick}>{isUserName ? `Я не ${username}.` : 'назад к авторизации'}</LinkButton>
        </Content>
    );
};
