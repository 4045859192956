import { ResponseError } from 'utils/api';

export interface ParamsGTM {
    eventValue?: string | number;
    login?: string;
}

export interface ParamsPhoneInputGTM extends ParamsGTM {
    success: boolean;
}

export enum EventContext {
    OTP_PAGE = 'ekran_otp',
    CONFIRMATION_PAGE = 'ekran_podtverzhdenie_lichnosti',
    KOD_FROM_SMS = 'ekran-kod_iz_sms',
    ANOTHER_LINKED_NUMBER = 'drugoi_privyazannyi_nomer',
    SOMEONE_ELSE_PHONE = 'chuzhoi_akkaunt',
}

export enum ScreenNamesGTM {
    LOGIN = '/login/mtsmoney/auth/login', // Экран ввода номера телефона
    CONFIRMATION_PASSPORT = '/login/mtsmoney/auth/confirm/passport', // Экран подтверждения личности по паспорту
    CONFIRMATION_CARD = '/login/mtsmoney/auth/confirm/card', // Экран подтверждения личности по номеру карты
    NAME_OR_NICKNAME = '/login/mtsmoney/auth/nickname', // Экран ввода имени или никнейма
    CONFIRM = '/login/mtsmoney/auth/confirm', // Показ экрана "Выбор способа подтверждения"= паспорт или карта
    WARNING = '/login/mtsmoney/auth/warning', // Показ экрана "В вашем аккаунте МТС Банка указан другой номер телефона" или "Ваш номер используется в чужом аккаунте"
    BIOMETRIYA = '/login/mtsmoney/auth/biometriya', // экрана подключения биометрии
    AUTH = '/login/mtsmoney/auth/', // экрана с выбором способа авторизации биометрия или номер телефона
    POLICY = '/login/mtsmoney/auth/politika_konfidencialnosti',
}

export interface AuthParamsGTM extends ErrorsParamsGTM {
    screenName: ScreenNamesGTM;
    eventContextGTM?: EventContext;
    eventLabel?: EventLabelsGTM;
    isBio?: boolean;
    message?: string;
}

export enum FilterName {
    EXHAUSTED_ATTEMPTS = 'Исчерпано количество попыток ввода смс',
    NEXT_STEP_REGISTER = 'пришел с бэка 200 и next_step  register',
    FAILED_LOGIN_BY_BIO = 'Не\u00A0удалось войти по\u00A0данным биометрии',
    FAILED_CONNECT_BIO = 'Не\u00A0удалось подключить вход по\u00A0данным биометрии',
}

export enum UserAuthGTM {
    UNAUTHENTICATED = '0',
    AUTHENTICATED = '1',
}

export enum ActionGroupGTM {
    CONVERSIONS = 'conversions',
    INTERACTIONS = 'interactions',
    NON_INTERACTIONS = 'non_interactions',
}

export enum TouchPointGTM {
    WEB = 'web',
    WEBVIEW = 'webview',
}

export enum EventActionGTM {
    SCREEN_SHOW = 'screen_show',
    LINK = 'link',
    FORM = 'form',
    FORM_ADD = 'form_add',
    ERROR_SHOW = 'error_show',
    BUTTON_CLICK = 'button_click',
    FORM_CONFIRMED = 'form_confirmed',
    POPUP_SHOW = 'popup_show',
    ELEMENT_STATUS = 'element_status',
}

export enum EventGTM {
    MTS_PAYMENTS_AUTH = 'mtsPaymentsAuth',
    MTS_PAGE_VIEW = 'mtsPageview',
}

export enum EventCategoryGTM {
    AUTH_FORM = 'auth_form',
}

export enum PageTypeGTM {
    SSO_AUTH = 'sso_auth',
}

export interface PagePropsGTM {
    heading: string;
    login: string;
    message: string;
    errorId?: string;
}

export enum EventContentGTM {
    TEMPORARILY_BLOCK = 'vremenno_zablokirovali_akkaunt',
    OFTEN_OTP = 'chasto_zaprashivaete_kod',
    INCORRECT_OTP = 'nevernyi_kod_sms',
    INCORRECT_DATA = '3_raza_nepravilnyiy_passport_ili_karta',
    ANOTHER_LINKED_NUMBER = 'drugoi_privyazannyi_nomer',
    SOMEONE_ELSE_PHONE = 'chuzhoi_akkaunt',
    CONFIDENCIAL = 'konfidencialnost',
    CARD_ENTRY = 'vvod_karty',
    PASSPORT_ENTRY = 'vvod_passport',
    OTP = 'otp',
    BIO = 'biometria',
    CONFIRMATION_METHOD = 'sposob_podtverzhdeniya',
}

export enum EventLabelsGTM {
    YES = 'klient_banka',
    NO = 'ne_klient_banka',
    ANOTHER_NUMBER = 'voiti_s_drugim_nomerom',
    CALL_TO_BANK = 'pozvonit_v_bank',
    PASSPORT = 'seriya_nomer_pasporta',
    ENTRY_PASSPORT = 'vvod_serii_i_nomera_pasporta',
    CARD_NUMBER = 'nomer_karty',
    ENTRY_CARD_NUMBER = 'vvod_nomer_karty',
    TRY_AGAIN = 'poprobovat_esche_raz',
    WRONG_TIED_NUMBER = 'drugoi_privyazannyi_nomer',
    WRONG_ACCOUNT = 'chuzhoi_akkaunt',
    LIMITED_MODE = 'voiti_v_ogranichennom_rezhime',
    OFFICES_MAP = 'otdeleniya_na_karte',
    RESEND_OTP = 'otpravit_kod_povtorno',
    CODE_FROM_SMS = 'kod_iz_sms',
    WRONG_CODE = 'kod_vveden_nekorrektno',
    ACCESS_CONDITIONS = 'usloviya_dostupa',
    POLYCI_CONFIDENCIAL = 'politika_konfidencialnosti',
    ACCEPT = 'prinyat',
    CONFIRMATION_SCREEN = 'ekran_podtverzhdeniya',
    CARD_MSB = 'karta_msb',
    ENTRY_BY_NUMBER = 'vhod_po_nomeru',
    NICK_NAME = 'nick_name',
    CONNECT_BIOMETRIYA = 'podkluchit_biometriu',
    LOGIN_METHOD = 'sposob_vhoda',
    SUCCESSFUL_AUTH = 'uspeshnaya_avtorizaciya',
    ENTRY_OTP = 'vhod_otp',
    RESOLVE = 'razreshit',
    BY_BIOMETRY = 'po_biometrii',
    BY_PHONE_NUMBER = 'po_nomeru_telefona',
    SKIP = 'propustit',
    IN_PERSONAL_ACC = 'v_lichnyi_kabinet',
    BACK = 'nazad',
    ENTRY_BY_BIOMETRIYA = 'vhod_biometriya',
    FAILED_CONNECT_WEBAUTH = 'ne_udalos_podkluchit_webauthn',
    REPEAT = 'povtorit',
    FURTHER = 'dalee',
    PASSPORT_INFO = 'dannye_pasporta',
    CARD_INFO = 'dannye_karty',
    WRONG_PASSPORT_DATA = 'nekorrektnye_dannye_pasporta',
    WRONG_CARD_DATA = 'nekorrektnye_dannye_karty',
    PHONE_NUMBER = 'nomer_telefona',
    NUMBER_ENTERED_INCORRECTLY = 'nomer_vveden_nekorrektno',
    WRONG_NAME = 'nekorretnoe_imya',
    CONNECTION_ERROR = 'oshibka_soedineniya',
    YOU_ARE_BANK_CLIENT = 'vy_klient_banka',
    TRY_AFTER_5_MIN = 'poprobuite_cherez_5_minut',
    NEED_CHECK = 'nuzhna_proverka',
    BIOMETRY_RECEIVED = 'biometriya_podkluchena',
}

export interface FiveMinPopUpClickPropsGTM extends ParamsGTM {
    callToBank: boolean;
    eventContent: string;
}

export interface ShowPopUpBlocFiveMinParamsGTM {
    eventContent: EventContentGTM;
    eventContext?: EventContext;
    filterName?: string;
    login?: string;
}

export interface IdentificationCLickPropsGTM {
    login: string;
    isPassport?: boolean;
}

export enum ButtonTextsGTM {
    CallToBank = 'Позвонить в банк',
    AnotherNumber = 'Войти с другим номером',
    OfficesMaps = 'Отделения на карте',
    Yes = 'Да, я клиент банка',
    No = 'Нет',
    Retry = 'Попробовать ещё раз',
}

export interface ShowPopRestrictedParams {
    isWrongNumber?: boolean;
}

export interface ClickAnotherNumberPopUpParams {
    eventLabel: EventLabelsGTM;
    isWrongAccount: boolean;
    isWrongNumber: boolean;
    login: string;
}

export interface ErrorsParamsGTM extends ParamsGTM {
    description?: string;
    error?: ResponseError;
    messageCode?: string;
}

export interface FailedAuthProps extends ErrorsParamsGTM {
    error?: ResponseError;
    eventContextGTM?: EventContext;
    isTypeCard?: boolean;
    message?: string;
}

export interface ClickBackGTM extends IdentificationCLickPropsGTM {
    isCards?: boolean;
    isLogin?: boolean;
    isNickName?: boolean;
    isOtp?: boolean;
}

export enum AuthTypeGTM {
    NICKNAME = 'NICKNAME',
    SIGN_POLICY = 'SIGN_POLICY',
    ADDITIONAL = 'ADDITIONAL',
    RESTRICTED = 'RESTRICTED',
    OTP = 'OTP',
    BIO = 'BIO',
}

export interface DoAuthPropsGTM {
    authTypeGTM: AuthTypeGTM;
    eventContextGTM?: EventContext;
    isTypeCard?: boolean;
}

export type DoAuthGTM = (login: string, gtmProps: DoAuthPropsGTM) => void;
