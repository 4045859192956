import { getPh, sendGmtEvent, setFailedAuthParamsGTM } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    AuthParamsGTM,
    AuthTypeGTM,
    DoAuthGTM,
    DoAuthPropsGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventContentGTM,
    EventGTM,
    EventLabelsGTM,
    FailedAuthProps,
    FilterName,
    PageTypeGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from './types';

const baseAuthParams = {
    event: EventGTM.MTS_PAYMENTS_AUTH,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    pageType: PageTypeGTM.SSO_AUTH,
    actionGroup: ActionGroupGTM.NON_INTERACTIONS,
};

/** Успешная авторизация по номеру телефона
 *  uth_form-form_confirmed-uspeshnaya_avtorizaciya-pin | auth_form-form_confirmed-uspeshnaya_avtorizaciya */
export const successfulAuthGTM = ({ screenName, login, isBio, eventContextGTM }: AuthParamsGTM) =>
    sendGmtEvent({
        ...baseAuthParams,
        screenName,
        eventContext: eventContextGTM || null,
        eventAction: EventActionGTM.FORM_CONFIRMED,
        eventLabel: EventLabelsGTM.SUCCESSFUL_AUTH,
        userAuth: UserAuthGTM.AUTHENTICATED,
        eventContent: isBio ? EventContentGTM.BIO : EventContentGTM.OTP,
        ph: getPh(login),
    });

/** Провал авторизации */
export const unsuccessfulAuthGTM = ({
    screenName,
    login,
    eventContextGTM,
    description,
    messageCode,
    message,
    error,
    eventLabel,
}: AuthParamsGTM) =>
    sendGmtEvent({
        ...baseAuthParams,
        ...setFailedAuthParamsGTM(error),
        screenName,
        eventContext: eventContextGTM || null,
        eventAction: EventActionGTM.ERROR_SHOW,
        eventLabel: eventLabel || EventLabelsGTM.ENTRY_OTP,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        ph: getPh(login),
        eventContent: description || null,
        filterName: messageCode || message || null,
    });

/** матчинг возможных типов авторизации */
const matchAuthType = {
    [AuthTypeGTM.OTP]: (login: string) => successfulAuthGTM({ login, screenName: ScreenNamesGTM.LOGIN }),
    [AuthTypeGTM.NICKNAME]: (login: string) => successfulAuthGTM({ login, screenName: ScreenNamesGTM.NAME_OR_NICKNAME }),
    [AuthTypeGTM.SIGN_POLICY]: (login: string) => successfulAuthGTM({ login, screenName: ScreenNamesGTM.POLICY }),
    [AuthTypeGTM.ADDITIONAL]: (login: string, { isTypeCard }: DoAuthPropsGTM) => {
        successfulAuthGTM({
            login,
            screenName: isTypeCard ? ScreenNamesGTM.CONFIRMATION_CARD : ScreenNamesGTM.CONFIRMATION_PASSPORT,
        });
    },
    [AuthTypeGTM.RESTRICTED]: (login: string, { eventContextGTM }: DoAuthPropsGTM) => {
        successfulAuthGTM({ login, eventContextGTM, screenName: ScreenNamesGTM.WARNING });
    },
    [AuthTypeGTM.BIO]: (login: string) => successfulAuthGTM({ login, screenName: ScreenNamesGTM.BIOMETRIYA, isBio: true }),
};

/** матчинг возможных типов неуспешных авторизаций */
const matchUnAuthType = {
    [AuthTypeGTM.OTP]: (params: FailedAuthProps) => unsuccessfulAuthGTM({ ...params, screenName: ScreenNamesGTM.LOGIN }),
    [AuthTypeGTM.NICKNAME]: (params: FailedAuthProps) => unsuccessfulAuthGTM({ ...params, screenName: ScreenNamesGTM.NAME_OR_NICKNAME }),
    [AuthTypeGTM.SIGN_POLICY]: (params: FailedAuthProps) => unsuccessfulAuthGTM({ ...params, screenName: ScreenNamesGTM.POLICY }),
    [AuthTypeGTM.ADDITIONAL]: ({ isTypeCard, ...params }: FailedAuthProps) => {
        unsuccessfulAuthGTM({
            ...params,
            screenName: isTypeCard ? ScreenNamesGTM.CONFIRMATION_CARD : ScreenNamesGTM.CONFIRMATION_PASSPORT,
            eventLabel: isTypeCard ? EventLabelsGTM.WRONG_CARD_DATA : EventLabelsGTM.WRONG_PASSPORT_DATA,
        });
    },
    [AuthTypeGTM.RESTRICTED]: (params: FailedAuthProps) => {
        unsuccessfulAuthGTM({ ...params, screenName: ScreenNamesGTM.WARNING });
    },
    [AuthTypeGTM.BIO]: (params: FailedAuthProps) =>
        unsuccessfulAuthGTM({
            ...params,
            screenName: ScreenNamesGTM.BIOMETRIYA,
            eventLabel: EventLabelsGTM.FAILED_CONNECT_WEBAUTH,
            message: FilterName.FAILED_CONNECT_BIO,
        }),
};

export const doAuthGTM: DoAuthGTM = (login: string, gtmProps) => {
    if (matchAuthType[gtmProps.authTypeGTM]) {
        matchAuthType[gtmProps.authTypeGTM](login, gtmProps);
    }
};

export const logFailedAuthGTM = (authTypeGTM: AuthTypeGTM, gtmParams: FailedAuthProps) => {
    if (matchUnAuthType[authTypeGTM]) {
        matchUnAuthType[authTypeGTM](gtmParams);
    }
};
