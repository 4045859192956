import { getGeoposition } from './getGeoposition';
import { getMapLink, defaultMapLink } from './getMapLink';

/**  Геттер для получения ссылки "Показать отделения на карте" */
export const getLinkToMap = async () => {
    try {
        const geoposition = await getGeoposition();
        const latitude = String(geoposition.latitude);
        const longitude = String(geoposition.longitude);

        return getMapLink({ latitude, longitude });
    } catch (error) {
        return defaultMapLink;
    }
};

/**  Хандл для "Показать отделения на карте" */
export const handleLinkToMap = async () => {
    const currentMapLink = await getLinkToMap();

    window.window.open(currentMapLink, '_blank', 'noopener, noreferrer');
};
